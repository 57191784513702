@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #2d3748; /* Dark background to match theme */
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #4a5568; /* Match darker gray */
    border-radius: 100vh;
    border: 3px solid #2d3748; /* Dark border for padding effect */
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #718096; /* Lighter gray on hover */
  }
}
